import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Helmet } from 'react-helmet' // Import Helmet for SEO
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactInfo from '@widgets/ContactInfo'
import Poster from '@widgets/Poster'
import BannerH1 from '@widgets/BannerH1'
import { Card as CardComponent } from 'theme-ui'
import { PostBody } from '@widgets/Post'

const Live = () => {
  const data = useStaticQuery(liveQuery2)
  const live = data.allSanityLive.nodes[0]
  const specific = data.allArticle.edges[0].node

  // Define Event Schema
  const eventSchema = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: 'ACS25 İstanbul',
    description:
      "17-18 Mayıs 2025 | Kardiyolojide zor olguları ve yeni gelişmeleri konuşmak için İstanbul'dayız",
    startDate: '2025-05-17',
    endDate: '2025-05-18',
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',
    location: {
      '@type': 'Place',
      name: 'Elite World Grand Hotel İstanbul Küçükyalı',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Aydınevler, İnönü Cd. No:22, 34854 Maltepe/İstanbul',
        addressLocality: 'İstanbul',
        addressCountry: 'TR'
      }
    },
    organizer: {
      '@type': 'Organization',
      name: 'ACS - Advanced Cardiovascular Solutions',
      url: 'https://acslive.org/acs25'
    },
    url: 'https://acslive.org/acs25',
    image: 'https://acslive.org/acs25.webp'
  }

  return (
    <>
      {/* Inject SEO Metadata */}
      <Helmet>
        <script type='application/ld+json'>
          {JSON.stringify(eventSchema)}
        </script>
      </Helmet>

      <Divider />
      <Stack>
        <BannerH1 />
      </Stack>
      <Seo title='ACS25 İstanbul | 17-18 Mayıs 2025' />
      <Divider />
      <Stack>
        <PageTitle
          header='ACS25 İstanbul'
          subheader="17-18 Mayıs 2025 | Kardiyolojide zor olguları ve yeni gelişmeleri konuşmak için İstanbul'dayız"
        />
      </Stack>
      <Stack
        effectProps={{ fraction: 0 }}
        direction={['column', 'row', 'row', 'row']}
      >
        <Main sx={{ mb: 4 }}>
          <Divider space={2} />
          <Divider space={0} />
          <CardComponent variant='paper'>
            <PostBody {...specific} />
          </CardComponent>
        </Main>
        <Sidebar>
          <Divider space={2} />
          <Poster no={1} />
          <Divider space={3} />
          <ContactInfo />
        </Sidebar>
      </Stack>
    </>
  )
}

const liveQuery2 = graphql`
  query liveQuery2 {
    allSanityLive {
      nodes {
        title
        subTitle
        videoLink
      }
    }
    allArticle(filter: { title: { eq: "ACS25 İstanbul" } }) {
      edges {
        node {
          body
        }
      }
    }
  }
`

export default Live
